<template>
  <section>
    <div class="contact-items">
      <div class="contact-items__item contact-item">
        <div class="contact-item__icon">
          <img :src="require('./images/home_ic.svg')" alt="">
        </div>
        <div class="contact-item__text">
          {{ $options.contactData.address }}
          <div class="contact-item__text-small">
            NIP: 1251501220; REGON: 141501690; KRS: 0000309499;
          </div>
        </div>
      </div>
      <div class="contact-items__item contact-item">
        <div class="contact-item__icon">
          <img :src="require('./images/mail_ic.svg')" alt="">
        </div>
        <div class="contact-item__text">
          <el-link :href="`mailto:${$options.contactData.email}`">
            {{ $options.contactData.email }}
          </el-link>
        </div>
      </div>
      <div class="contact-items__item contact-item">
        <div class="contact-item__icon">
          <img :src="require('./images/phone_ic.svg')" alt="">
        </div>
        <div class="contact-item__text">
          <div class="contact-item__text-small">
            {{ $options.contactData.phoneContactName }}
            <el-link :href="`phone:${$options.contactData.phoneContactToCall}`">
              {{ $options.contactData.phoneContactToShow }}
            </el-link>
          </div>
         <div class="contact-item__text-small">
           {{ $options.contactData.phoneTechnicalName }}
           <el-link :href="`phone:${$options.contactData.phoneTechnicalToCall}`">
             {{ $options.contactData.phoneTechnicalToShow }}
           </el-link>
         </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

const contactData = {
  address: 'Pallotyńska Fundacja Misyjna Salvatti.pl \n ul. Wilcza 8, 05-091 Ząbki',
  email: 'pomoc@salvatti.edu.pl',
  phoneContactName: 'Sprawy merytoryczne - Monika Mostowska',
  phoneContactToShow: '698-815-636',
  phoneContactToCall: '+48698815636',
  phoneTechnicalName: 'Sprawy techniczne i współpraca - Radosław Mutlan',
  phoneTechnicalToShow: '604-425-459',
  phoneTechnicalToCall: '+48604425459',
}

export default {
  name: 'ContactPage',
  inject: ['changePageTitle'],
  contactData,
  created() {
    this.changePageTitle('page_contact.title')
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/grid-mixins";
.contact-items {
  margin-top: 2em;
}
.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 2.9em;
  &__icon {
    img {
      width: 100%;
    }
    @include tablet {
      max-width: 6em;
    }
    @include xs-smartphone {
      max-width: 4em;
    }
  }
  &__text {
    font-size: 2.2em;
    padding-left: 1em;
    white-space: break-spaces;
    @include tablet {
      font-size: 1.5em;
    }
    @include xs-smartphone {
      font-size: 1em;
      padding-left: 0.5em;
    }
    .el-link {
      font-size: inherit;
    }
  }
  .contact-item__text-small {
    font-size: 0.68em;
  }
}
</style>
